
import { orderBy } from 'lodash'
import { useLocalstorage } from '../localStorage/store'
import type {GmModelLibraryModelsProductModel} from "~/gen/openapi/sblService";
import {ProductApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()

const state = reactive({
  products: persist<GmModelLibraryModelsProductModel[]>('products', []),
  lastFetchedProducts: persist<number>('lastFetchedProducts', 0),
})

const products = computed((): GmModelLibraryModelsProductModel[] => orderBy(state.products, 'Name'))
const lastFetchedProducts = computed((): number => state.lastFetchedProducts)

const clearProducts = (): void => {
  state.products = []
}

const setProducts = (products: GmModelLibraryModelsProductModel[]): void => {
  state.products = products
}

const loadProducts = async (force?: boolean): Promise<void> => {
  if (!force &&
      state.products.length &&
      lastFetchedProducts.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }

  const { axiosClient } = useAxiosClient()
  const productApi = new ProductApi(undefined, '', axiosClient.value)

  try {
    const products: GmModelLibraryModelsProductModel[] = (await productApi.productGetProducts()).data
    setProducts(products)
    state.lastFetchedProducts = Date.now()
  } catch (e) {
    console.error('Failed to load products', e)
  }
}

export const useProductsStore = () => ({
  clearProducts,
  loadProducts,
  products
})
